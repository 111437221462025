<template>
  <main>
    <div class="page-header">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="d-lg-flex align-items-end justify-content-between align-items-center">
          <h1 class="page-title mb-3 mb-md-2">Manage Digital Certificates</h1>
          <a class="btn btn-lg btn-info mb-2" href="#"><svg class="icon icon-add"><use xlink:href="/icons/symbol-defs.svg#icon-add"></use></svg> Upload a Digital Certificate</a>
        </div>
      </div>
    </div>

    <div class="container-fluid">

      <h2 class="mb-4">Pending Uploads:</h2>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
          <div class="card">
            <h4 class="card-header bg-pending">
              JSmith - LS2
            </h4>
            <div class="card-body">
              <h5>JaneSmith-LS2.pfx</h5>
              <ul class="list-unstyled mb-0">
                <li><strong>status:</strong> <span class="text-info">Upload in Progress</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
          <div class="card">
            <h4 class="card-header bg-pending">
              JSmith - LS3
            </h4>
            <div class="card-body">
              <h5>JaneSmith-LS3.pfx</h5>
              <ul class="list-unstyled mb-0">
                <li><strong>status:</strong> <span class="text-danger">Upload Failed</span></li>
              </ul>
              <p class="text-danger mb-0"><small>This Certificate is not registered as "Active" with juricert. Please upload another Certificate [10008]</small></p>
            </div>
            <div class="card-footer">
              <button class="btn btn-md btn-link text-danger">Remove</button>
            </div>
          </div>
        </div>
      </div>

      <h2 class="mt-4 mb-4">Your digital certificates:</h2>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
          <div class="card">
            <h4 class="card-header d-flex justify-content-between align-items-center">
              JSmith - ASR
              <svg class="icon icon-check text-success"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
            </h4>
            <div class="card-body">
              <h5>JaneSmith.pfx</h5>
              <ul class="list-unstyled mb-0">
                <li><strong>ID:</strong> GWT6EP</li>
                <li><strong>Expires:</strong> Jun 10, 2018</li>
                <li><strong>status:</strong> <span class="text-success">Active</span></li>
              </ul>
            </div>
            <div class="card-footer">
              <button class="btn btn-md btn-link">Edit</button>
              <button class="btn btn-md btn-link text-danger">Remove</button>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
          <div class="card">
            <h4 class="card-header d-flex justify-content-between align-items-center">
              JSmith - LS
              <svg class="icon icon-check text-success"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
            </h4>
            <div class="card-body">
              <h5>JaneSmith-LS.pfx</h5>
              <ul class="list-unstyled mb-0">
                <li><strong>ID:</strong> X3TWPW</li>
                <li><strong>Expires:</strong> Jun 10, 2018</li>
                <li><strong>status:</strong> <span class="text-success">Active</span></li>
              </ul>
            </div>
            <div class="card-footer">
              <button class="btn btn-md btn-link">Edit</button>
              <button class="btn btn-md btn-link text-danger">Remove</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Manage Certificates"></help-panel>
  </main>

</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'help-panel': HelpPanel
  },

  data () {
    return {

    }
  }
}
</script>

